export const pagination = {
  data() {
    return {
      total: null,
      flag: false
    }
  },
  methods: {
    // 条数
    handleSizeChange(newSize) {
      this.queryInfo.size = newSize

      this.flag = true

      this.search()
    },

    // 分页
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage

      this.flag = true

      this.search()
    },
    
    getRowClass(){
      return 'background:#f8f8f8;'
    },
  }
}
