<template>
  <div class="user">
      <div class="line_one">
        <el-form inline :model="queryInfo" label-width="100px" ref="form">
					<el-form-item label="手机号:">
						<el-input v-model="queryInfo.mobile" placeholder="请输入手机号" clearable style="width:280px"/>
					</el-form-item>
					<el-form-item label="姓名:">
						<el-input v-model="queryInfo.userName" placeholder="请输入姓名" clearable style="width:280px"/>
					</el-form-item>
					<el-form-item>
						<el-button @click="reset">重置</el-button>
					</el-form-item>
					<el-form-item>
						<el-button class="btn" type="primary" @click="search()">搜索</el-button>
					</el-form-item>
				</el-form>
      </div>
      <div class="line_two">
        <div class="buttonArea">
					<div class="add" @click="publishTopic()">
            <i class="iconfont icon-jr-icon-meet"></i>
            批量推送
          </div>
        </div>
        <el-table :data="dataUserList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass" @select="select" @select-all="select">
        <el-table-column type="selection" width="55" align="center"/> 
        <el-table-column prop="id" label="用户ID"  width="80" align="center"/>
       	<el-table-column label="头像" width="100" align="center">
          <template v-slot="{ row }">
            <div class="headImg">
              <img
              :src="row.headPortrait"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="真实姓名" prop="userName" align="center" width="80"/>
        <el-table-column label="手机号" prop="mobile" align="center" width="150"/>
        <el-table-column label="机构" prop="institution" align="center" width="120" />
        <el-table-column label="职位" prop="position" align="center" width="120" />
				 <el-table-column label="标签" prop="labelList" align="center"  width="300">
         <template v-slot="{ row }">
            <div class="tip_two">
              <div v-for="(item,index) in row.labelList" :key="index">{{item}}</div>
            </div>
          </template>
        </el-table-column>
     		<el-table-column label="用户等级" prop="whetherMember" align="center"  width="100">
          <template v-slot="{ row }">
            <div>
              {{row.whetherMember == 0 ? '普通会员' : '会员用户'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户简介" align="center" width="400px">
          <template v-slot='{ row }'>
            <textFlow :info="row.personalIntro" :lineClamp='2' :hiddenBtn="true" v-if="row.personalIntro"></textFlow>
            <div v-else>暂无数据</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <div class="caoZuo">
              <div class="colorOne" style="margin-right:10px" @click="publishTopicOne(row.id)">推送</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
  </div>
</template>
<script>
import textFlow from '@/components/textflow.vue'
import { pagination } from '@/mixins/pagination'
import { getAllUser, messagePush } from '@/api/infoPush.js'
const defaultQueryInfo = Object.freeze({
  current: 0,
  size: 10,
  mobile:'',
  userName:'',
})
export default {
  name: 'User',
  mixins: [pagination],
  components: { textFlow },
  data() {
    return {
      dataUserList: [],
      detailList: [],
      selectionArr: [],
      loading: false,
      dataList:{},
      queryInfo: { ...defaultQueryInfo },
      userList:{}
    }
  },
  created() {
    this.userList.messageId = this.$route.query.id
    // console.log(this.$route.query.id);
    this.search()
  },
  methods: {
    // 全选单选
     select(selection){
      this.selectionArr = selection
    },
    //重置查询条件
    reset() {
      this.queryInfo = {
        pageNum: 0,
        pageSize: 10,
      }
      this.search()
    },
    async search() {
      this.loading = true
      const { data: res } = await getAllUser(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    async publishTopic( ) {
      this.$confirm(`确认批量推送该话题给所选的用户吗?`, {
        type: 'warning'
      })
      .then(async() => {
        if(this.selectionArr.length == 0) {
          this.$message.error('请选择要推送的用户!')
          return false
        }
        this.userList.userId = []
        this.selectionArr.forEach(item =>{
          this.userList.userId.push(item.id)
        })
        // console.log(this.userList,'paramsList1');
        const { data: res } = await messagePush(this.userList)
        if (res.resultCode === 200) {
          this.$message.success(`批量推送成功`)
          this.search()
        } else {
          this.$message.error('推送失败，请重试。')
        }
      })
      .catch(() => {})
    },
    publishTopicOne(id){
      this.$confirm(`确认推送该话题给所选的用户吗?`, {
        type: 'warning'
      }).then(async() => {
        this.userList.userId = []
        this.userList.userId.push(id)
        // console.log(this.userList,'paramsList2');
        const { data: res } = await messagePush(this.userList)
        if (res.resultCode === 200) {
          this.$message.success(`推送成功`)
          this.search()
        } else {
          this.$message.error('推送失败，请重试。')
        }
      })
      .catch(() => {})     
    },
  }
}
</script>

<style lang="less" scoped>
.headImg{
  margin: 0px auto;
  width: 45px;
  height: 45px; 
  img{
    width: 100%;
    height: 100%;
  }
}
.tip_two{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div{
    margin: 5px 5px;
    padding: 2px 8px;
    border-radius: 5px;
    background: rgba(112, 103, 242, 0.1);
    color: #7067F2;
  }
}
.colorOne{
  color: #7C71E1;
  cursor: pointer;
}
.user {
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  .line_one{
    background: #ffffff;
    border-radius: 12px;
    padding-top: 20px;
  } 
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    .buttonArea{
      display: flex;
      margin-top: 20px;
      div{
        width: 100px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add{
        background: #7C71E1;
        color: #ffffff;
      }
    }
  }
  .caoZuo{
    display: flex;
    justify-content: center;
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
  .data_list {
    width: 100%;
    margin: 20px 0px 0px 15px;
  }
}

</style>
