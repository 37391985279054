import request from '@/utils/request'

//消息推送--添加消息
export function addInfo(data) {
    return request({
        url: '/businessMessage/add',
        method: 'post',
        data
    })
}

//消息推送--分页查询
export function getAllInfo(data) {
    return request({
        url: '/businessMessage/all',
        method: 'post',
        data
    })
}

//消息推送--批量删除
export function deleteInfo(data) {
    return request({
        url: '/businessMessage/del',
        method: 'post',
        data
    })
}

//消息推送--更新
export function updateInfo(data) {
    return request({
        url: '/businessMessage/update',
        method: 'put',
        data
    })
}

//消息推送--根据id查
export function getDetail(data) {
    return request({
        url: `/businessMessage`,
        method: 'get',
        params: data
    })
}

//消息推送--所有会员用户
export function getAllUser(data) {
    return request({
        url: `/businessMessage/allUser`,
        method: 'post',
        data
    })
}

//消息推送--批量推送
export function messagePush(data) {
    return request({
        url: `/businessMessage/push`,
        method: 'post',
        data
    })
}

//消息推送--按条件查询消息是否已读用户
export function getReadUser(data) {
    return request({
        url: `/businessMessage/readUser`,
        method: 'post',
        data
    })
}